.badge-wrapper
  position: absolute;
  left: 0
  top: 0
  z-index: 2
  pointer-events: none;

.badge-icon-wrapper
  display: flex;
  align-items: center;
  justify-content: center;
  box(110px)
  .brand_tretti &
    box(130px)
  +below($mobile)
    box(90px)

.badge-icon
  position: absolute;
  box(100%)
  left: 0
  top: 0
  z-index: 3

.badge-discount-text
  position: relative;
  font-family: $font_family_header
  font-size: 1.8rem
  font-weight: bold
  color: $offer_text_color
  z-index: 4
  .shop_SE &
    color $offer_text_color_se
  .brand_tretti &
    font-size: 2.7rem
  .brand_skousen &
    font-size: 2.3rem
  .brand_lph &,
  .brand_enemo &,
  .brand_bilka &,
  .brand_wupti &,
  .brand_foetex &
    font-size: 2.9rem

.badge-discount
  svg
    fill:$offer_background_color
    .shop_SE &
      fill:$offer_background_color_se
  .brand_tretti &
    transform: rotateZ(-15deg);
    top: -20px

//VIP best in test badge
.badge__best-in-test
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;