$thumb_size = 62px

.vip__gallery
  width 51%
  position: relative
  *::selection
    background: none
  +below($desktop)
    width 90%
    margin 0 auto 12px 
  +below($tablet)
    width 100%

.gallery__details__outer
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .brand_wupti &
    background-color: #fff;
    border-radius: 5px;
    padding: 30px 0;
  +mobile()
    justify-content: center;


// CONTROLS
.gallery__next, .gallery__previous
  circle(40px)
  position: absolute;
  top calc(50% - 20px)
  display: flex;
  cursor: pointer
  transition: all 200ms
  z-index: 1
  svg
    margin: auto;
    fill rgba(0,0,0,.5)
    box(60%)
  &:hover
    svg
      fill rgba(0,0,0,.7)
  .shop_SKOU &, .shop_SKOUNO &
    circle(50px)
    svg
      fill $neutral_color
      opacity: 0.5
    &:hover
      svg
        fill $neutral_color
        opacity: 0.7
  +mobile()
    position: absolute;
    top 35vw
    margin-top: -20px
    z-index: $gallery_controls_Z

.gallery__next
  right: 0
  .at-end &
    opacity: 0
    pointer-events: none;
  +mobile()
    right: 2px

.gallery__previous
  left: 0
  .at-beginning &
    opacity: 0
    pointer-events: none;
  +mobile()
    left 2px


// DETAILS PANE

.gallery__details
  display: flex;
  justify-content: center;
  align-items: center;
  box(500px)
  overflow: hidden;
  position: relative;
  +below($wide)
    box(400px)
  +below($desktop)
    box(500px)
  +below($tablet)
    box(300px)
  +below($mobile)
    box(75vw)

.gallery__details__inner
  white-space: nowrap;
  position: absolute;
  font-size 0
  left: 0
  top 0
  transition: transform 450ms $easeOutCubic
  cursor: zoom-in;
  &.dragging
    transition: transform 0ms
    cursor: grabbing;

.gallery__details__img
  display: inline-block;
  vertical-align: top
  box(500px)
  position: relative;
  +below($wide)
    box(400px)
  +below($desktop)
    box(500px)
  +below($tablet)
    box(300px)
  +below($mobile)
    box(75vw)
  img
    width: 100%

.gallery__details__video
  box(100%)
  display: flex;
  align-items: center;
  justify-content: center;
  &.rendered
    img
      display none
    .gallery__details__play-icon
      display none

.gallery__details__play-icon
  position: absolute;
  box(100%)
  top 0
  left 0
  display: flex;
  align-items: center;
  justify-content: center;

// THUMBS
.gallery__thumbs_outer
  overflow: hidden;
  box(412px, 70px)
  position: relative;
  margin: 0 auto
  +below($mobile)
    display: flex;
    justify-content: center;
    box(100%)

.gallery__thumbs
  white-space: nowrap;
  position: absolute;
  display: flex;
  align-items center
  top 0
  left 0
  cursor: pointer
  transition: left 400ms cubic-bezier(0.645, 0.045, 0.000, 0.880)
  +below($mobile)
    position: static;
    width: 90vw
    justify-content: center;
  .displayed
    .gallery__thumb
      border: solid 2px $brand_color
      +below($mobile)
        border: solid 2px $brand_color
        background-color $brand_color
        &.thumb__is__video
          .gallery__thumbs__video-icon__inner
            background-color transparent


.gallery__thumb-wrapper
  position: relative;
  &.loading::before
    preloader()
  &.loading
    img
      display: none

.gallery__thumb
  position: relative;
  box($thumb_size)
  margin: 0 8px 0 0
  border solid 1px $gray_border
  border-radius: $gallery_thumb_radius;
  img
    border-radius: $gallery_thumb_radius;
    height: 100%
    width: 100%
  picture
    box(100%) //ie11 fix
  +below($mobile)
    img
      display none
    &.thumb__is__image
      circle(14px)
      border solid 1px $black
      margin: 0 3px
    &.thumb__is__video
      circle(20px)
      border solid 2px $black
      margin: 0 3px
  &.thumb__is__video
    display flex
    justify-content center
    align-items center
    flex-direction column
        
.gallery__thumbs__video-icon
  position absolute
  box(100%)
  display flex
  justify-content center
  align-items: center;
  top 0
  left 0

.gallery__thumbs__video-icon__inner
  box(50%, 40%)
  background-color: alpha($brand_color, .8)
  display: flex;
  align-items: center;
  justify-content: center;
  &:after
    content: ''
    display: block;
    box(0)
    border: 9px solid transparent
    border-left-color #fff
    border-top-width: 5px
    border-bottom-width: 5px
    border-right-width: 0
    margin-left 3px
  +below($mobile)
    box(100%)
    background-color: transparent
    &:after
      border-left-color $black
      .displayed &
        border-left-color #fff

.gallery__thumbs__video-icon__inner
  &.youtube
    background-color $youtube_color
    border-radius 15%
    +below($mobile)
      background-color: transparent


// Gallery full screen

.gallery-full
  display: flex
  flex-direction: column;
  justify-content: space-around;
  background: rgba(0,0,0,0)
  position: fixed;
  z-index: $gallery_overlay
  visibility: hidden;
  pointer-events: none;
  transition: background 100ms, all 500ms cubic-bezier(0.165, 0.840, 0.440, 1.000)
  overflow: hidden;
  *::selection
    background: none
  .gallery__next, .gallery__previous
    circle(60px)
    top calc(50% - 30px)
    z-index: 1
    svg
      fill #fff
    &:hover
      svg
        opacity: 0.8
    .shop_SKOU &, .shop_SKOUNO &
      svg
        fill $neutral_color
        opacity: 0.6
      &:hover
        svg
          fill $neutral_color
          opacity: 0.9
  .gallery__next
    margin-right: 30px
    +below($desktop)
      margin-right 20px
  .gallery__previous
    margin-left: 30px
    +below($desktop)
      margin-left 20px
  .gallery__details__outer
    justify-content: space-around;
    opacity: 0
    transition: opacity 300ms ease
    +below($desktop)
      padding-top 40px
  .gallery__details__inner
    cursor: grab;
    &.dragging
      cursor: grabbing;
  .gallery__thumbs_outer
    opacity: 0
    transition: opacity 300ms ease

  &.show-gallery
    top 0 !important
    left 0 !important
    width: 100vw !important
    height: 100vh !important
    visibility: visible;
    pointer-events: all;
    background: rgba(0,0,0,.8)
    .gallery__details__outer
      opacity: 1
      transition-delay: 800ms
    .gallery__thumbs_outer
      opacity: 1
      transition-delay: 800ms

  +mobile()
    display: none;
.video-overlay
  +mobile()
    display flex
.gallery-full__close
  color: rgba(255,255,255, .8)
  font-size: 3rem
  position: absolute;
  right 50px
  top 0
  cursor: pointer;
  z-index 1
  &:hover
    color: rgba(255,255,255, 1)


.gallery-full__details
  overflow: hidden;
  position: relative;
  box(100vmin, 85vmin)

.gallery-full__details__img
  display: inline-block;
  vertical-align: top;
  box(100vmin, 85vmin)
  position: relative;

.gallery-full__details__video
  box(100vmin, 85vmin)
  position: relative;
  display: flex;
  align-items: center;
  & > *
    width: 100%
  &.rendered
    img
      display none
    .gallery__details__play-icon
      display none

//Preloader on the gallery
.gallery__details__placeholder
  &.loading::before
    content: "";
    circle(90px)
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom:0;
    margin: auto;
    border: 5px solid rgba(0, 0, 0, 0.3)
    border-top: 5px solid $brand_color
    animation: sweep 600ms infinite ease;
  &.loading
    img
      visibility: hidden;

.gallery-full__details__img .loading
  background-color: #fff;
  height: 100%;

.video-overlay__container
  height auto
