//Main footer
.footer-main
  background: $footer_main_background_color
  color: $footer_main_text_color;
  padding-top: 25px
  padding-bottom: 30px;
  +below($wide)
    padding: 15px;
  +below($tablet)
    margin-top:30px;
  h4
    color: $footer_main_header_color;
    font-size: 20px;
    font-weight: $footer_header_font_weight
    margin: 10px 0;
  a
    color: $footer_main_text_color;

.footer-main__inner
  max-width: $page_width
  margin: 0 auto
  display: flex;
  justify-content: space-around;
  +below($mobile)
    text-align center
    flex-direction: column;

.footer-main__column
  +below($phablet)
    width: 100%;
    margin-bottom: 15px;
  ul
    list-style: none;
    margin 0
    padding 0
  li
    margin-bottom: 4px
  img
    margin-top 7px

//Sub footer
.footer-sub
  background: #ccc;
  color: $footer_main_text_color;
  padding-top: 20px
  padding-bottom: 20px;

.footer-sub__inner
  margin: 0 auto
  display: flex;
  justify-content: center;

.footer-sub__country
  display: flex
  align-items: center;
  font-size: 0.875rem
  margin: 0 10px
  color: $footer_main_text_color;
  svg
    box(25px)
    margin-right: 4px;

.footer__main-icons-partners
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  gap: 28px;

  img
    max-height: 24px;
    +below($mobile)
      max-height: 20px;
  .co2
    max-height: 46px
    img
      box(100px, 46px)
      max-height: 46px

.footer__main-icons-partners-mobile
  display: flex;
  justify-content: center
  flex-wrap: wrap;
  gap: 28px;
  padding-top: 16px;

  img
    max-height: 24px;