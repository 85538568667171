//Global list item
.list-item
  margin-bottom 2px
  padding-left 15px
  position relative
  font-size 0.875rem
  +below($tablet)
    font-size: 0.75rem
  &:before
    content ''
    position absolute
  .brand_whiteaway &,
  .brand_lph &,
  .brand_enemo &
  .brand_bilka &
  .brand_wupti &
  .brand_foetex &
    &:before
      circle(0px)
      left 2px
      top 5px
      border 5px solid transparent
      border-left-color: $black
      +below($tablet)
        top 3px
  .brand_skousen &
    &:before
      circle(6px)
      left 0
      top 5px
      border 1px solid #6d9197
      +below($tablet)
        top 5px
  .brand_tretti &
    &:before
      circle(6px)
      left 2px
      top 7px
      background-color $black
      +below($tablet)
        top 6px
