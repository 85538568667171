.brand_tretti,
.brand_lph,
.brand_enemo,
.brand_bilka,
.brand_wupti,
.brand_foetex
  .campaign-banner__wrap
    position: relative;
    margin-right: -21px
    +below($tablet)
      margin-bottom 10px
    +below($mobile)
      margin-right -1px
    &.double-banner
      margin-top 15px
      +below($desktop)
        margin-top 5px
      +below($mobile)
        margin-top -5px

  .grid
    .campaign-banner__wrap
      margin-right -16px
    .campaign-banner__info
      font-size 0.75rem

  .campaign-banner
    position relative
    overflow auto

  .campaign-banner__info
    float right
    display flex
    align-items stretch
    justify-content center
    position relative
    padding 5px 10px
    background-color $srp_neutral_bg_color
    min-height: 42px
    &.offer
      background-color $offer_background_color
      color $offer_text_color
      a
        color $offer_text_color

  .campaign-banner__info__left,
  .campaign-banner__info__right
    display flex
    flex-direction column
    align-items center
    justify-content center
    span
      line-height 1rem
      text-align center
    a
      color $black
    .force-one-line
      white-space: nowrap;

  .campaign-banner__info__left
    padding-right 8px
    z-index: 1
    .top
      text-transform uppercase
      font-weight bold
    .bottom
      font-size 0.75rem
      white-space: nowrap;

  .grid .campaign-banner__info__left
    display none

  .campaign-banner__info__right
    border-left 1px dashed darken($srp_neutral_bg_color, 20%)
    padding-left 8px
    z-index: 1
    .top
      font-weight bold
    .bottom
      font-size 0.75rem
    &.no-left-text
      border: none
      padding-left 0

  .grid .campaign-banner__info__right
    border none !important
    padding-left 0 !important

  .campaign-banner__extra-info-wrapper
    display: flex
    justify-content: flex-start
    align-items: center

  .campaign-banner__extra-info
    color $srp_faded_dark_color
    font-size 0.7rem
    margin-right: 12px;
    display block
    text-align right
    padding-top 2px
    +below($desktop)
      font-size 0.75rem

// Brand specific styles
.brand_tretti,
.brand_enemo
  .campaign-banner__wrap
    &.offer
      color: #fff
